<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <UserGroupAdd
      ref="userGroupAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-user-group="addUserGroup($event)" />
    <UserGroupDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-user-group.sync="initialUserGroup"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-user-group="editUserGroup($event)" />
    <UserGroupFilter
      :search.sync="filter.search"
      :status-filter.sync="filter.statusFilter"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Group"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(workingDay)="data">
          <span
            v-if="data.value.length <= 0"
            class="d-block">
            Undefined
          </span>
          <span
            v-else
            class="d-block">
            {{ data.value | formatDayOfWeek }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import UserGroupAdd from '@/components/Users/Group/UserGroupAdd.vue'
import UserGroupDetail from '@/components/Users/Group/UserGroupDetail.vue'
import UserGroupFilter from '@/components/Users/Group/UserGroupFilter.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import GroupProvider from '@/resources/GroupProvider'

const GroupService = new GroupProvider()

export default {
  components: {
    UserGroupAdd,
    UserGroupDetail,
    DeleteModal,
    TableTop,
    UserGroupFilter
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        search: '',
        statusFilter: null
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'startTime', label: 'Start Time', sortable: true },
        { key: 'endTime', label: 'End Time', sortable: true },
        // { key: 'workingDay', label: 'Working Day' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      selectedItem: {},
      initialUserGroup: {},
      deleteItem: {}
    }
  },
  created () {
    this.getUserGroup()
  },
  methods: {
    async getUserGroup () {
      try {
        const data = await GroupService.paginate(
          this.pagination.page, this.pagination.limit,
          this.filter.search, this.filter.statusFilter
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Get Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 User Group has been Failed'
          }
        })
      }
    },
    async addUserGroup (val) {
      try {
        await GroupService.createGroup(val)
        await this.getUserGroup()
        this.resetFormAndToggleSideBar(this.$refs.userGroupAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User Group has been added'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or name is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async editUserGroup (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await GroupService.updateGroup(val.id, payload)
        await this.getUserGroup()
        this.setInitialUserGroupAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User Group has been updated'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or name is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async confirmDelete (val) {
      try {
        await GroupService.deleteGroup(val.ID)
        await this.getUserGroup()
        this.isSidebarDetailActive = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User Group has been failed'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Failed',
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 User Group has been deleted'
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setInitialUserGroupAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialUserGroupAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialUserGroup = { ...val }
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    fetchFilter () {
      this.setPagination(1)
      this.getUserGroup()
    },
    sortFilter () {
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getUserGroup()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}

</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
